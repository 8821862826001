//main styles
.main-wrapper
	padding 0 0 0 0
	min-width $min-width
	width 100%
	position relative
	overflow hidden
	min-height 100%
	display flex
	flex-direction column
	justify-content flex-start


.wrapper
	min-width $min-width
	max-width $max-width
	padding 0 20px
	margin 0 auto
	position relative

/* titles */
p
	font($base-font-size, $base-line-height, $text-color, $custom-font-family, 400)
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	text-align: center;
	color: #FFFFFF
	max-width: 516px
	margin: 0 auto 50px

h1
	font($font-size-h1, $line-height-h1, $title-color, $custom-font-family, 700)
	font-weight 700
	font-size: 50px;
	line-height: 65px;
	text-align: center;
	color: #FFFFFF
	margin-bottom 10px

h2
	font($font-size-h2, $line-height-h2, $title-color, $custom-font-family, 700)

h3
	font($font-size-h3, $line-height-h3, $title-color, $custom-font-family, 700)

a
	font-weight: 600;
	font-size: 18px;
	line-height: 18px;
	align-items: center;


/* styles */
.main
	padding-top 60px
	padding-bottom 120px
	min-width $min-width
	width 100%
	flex-grow 1
	background-image: url('../img/main/bg.jpg')
	background-repeat: no-repeat
	background-size: cover


.hero
	&__wrapper
		display flex
		justify-content: center
		gap: 20px
		flex-wrap: wrap
	&__item
		width: 336px
		height: 327px
		background-repeat no-repeat
		background-size contain
		display flex
		flex-direction: column
		padding: 46px 30px 36px
		justify-content: flex-start
		border-radius: 30px;
		background-image: url('../img/main/bg.png')
		background-repeat: no-repeat
		background-size: 100% 100%
		background-position: center
		filter: drop-shadow(14px 14px 20px rgba(0, 0, 0, 0.08));
		&-role
			font-weight: 600;
			font-size: 20px;
			line-height: 30px;
			align-items: center;
			text-align: right;
			color: #FFFFFF;
			opacity 1
			margin: 0 0 2px 0
		&-license
			font-weight: 400;
			font-size: 13px;
			line-height: 20px;
			align-items: center;
			text-align: right;
			color: #FFFFFF;
			opacity 1
			margin: 0 0 4px 0
		&-number
			font-weight: 500;
			font-size: 13px;
			line-height: 14px;
			align-items: center;
			text-align: right;
			color: #FFD11B;
			opacity 1
			margin: 0
		&-line
			display flex
			justify-content: space-between
			align-items: center
			&:not(:last-child)
				margin-bottom 20px
			&-label
				font-weight: 400;
				font-size: 16px;
				line-height: 20px;
				color: #000;
				text-align left
				opacity .8
			&-amount
				font-weight: 700;
				font-size: 22px;
				line-height: 24px;
				align-items: center;
				color: #000;
		&-pic
			width: 218px
			display flex
			align-items: center
			justify-content: center
			margin: auto
			border-radius: 10px;
			margin-bottom 25px
		&-button
			font-size 22px
			line-height 22px
			font-weight 700
			color: #fff
			display block
			width: 278px
			text-align center
			background-image: url('../img/main/bg-button.png')
			background-size: cover
			padding: 21px 0 34px
			margin auto auto 0
			position relative
			&:hover
				transform: scale(1.05) rotate(-1deg)



