.footer
	width 100%
	min-width $min-width
	background: #4E47EC;
	padding-top 50px
	&__title
		font-size 25px
		line-height 25px
		color: #fff
	&__wrapper
		margin-top 50px
		display flex
		justify-content: space-between
		gap: 54px 21px
		flex-wrap: wrap
		margin-bottom 50px
	&__item
		width: 245px
		&-title
			font-weight 600
			font-size 16px
			line-height 22px
			color: #fff
			margin-bottom 10px
			max-width: 245px
		&-desc
			margin: 0
			text-align left
			max-width: 245px
			font-size 14px
			line-height 24px
			color: rgba(255, 255, 255, .5)
	&__list
		margin-top 20px
		margin-bottom 50px
		&-item
			font-size 14px
			line-height 24px
			list-style-type disc
			color: #fff
			margin-left 15px
			&:not(:last-child)
				margin-bottom 15px
	&__desc
		display flex
		gap: 20px
		margin-top 20px
		margin-bottom 50px
		p
			margin: 0
			text-align left
			font-size 14px
			line-height 24px
			color: rgba(255, 255, 255, .5)

	&__copyright
		padding: 18px 0
		color: #fff
		font-size 16px
		line-height 22px
		font-weight 500
	.wrapper__copyright
		background: #4942E8;
		max-width: 100%
		margin-top 24px
