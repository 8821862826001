body {
  position: relative;
  height: 100%;
  background-color: #fff;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-weight: normal;
  font-family: 'Exo2', sans-serif;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {
  display: block;
  max-width: 100%;
}
input[type="text"],
input[type="email"],
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
table {
  border-collapse: collapse;
  border-width: 0px;
  padding: 0px;
  margin: 0px;
}
html {
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}
input,
textarea {
  color: #000;
  font-family: 'Exo2', sans-serif;
  border-radius: 0;
}
input[type="button"],
input[type="submit"],
button {
  cursor: pointer;
  font-family: 'Exo2', sans-serif;
  font-size: 14px;
  line-height: 18px;
}
td {
  margin: 0px;
  padding: 0px;
}
ul {
  list-style: none;
}
form {
  padding: 0px;
  margin: 0px;
}
a {
  color: #100da9;
  transition: all 0.25s ease-out;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
a,
span,
div,
button {
  outline: none !important;
}
input[type=submit],
input[type=button],
button {
  -webkit-appearance: none;
  outline: none;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfix:after,
.wrapper:after,
.row:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.loaded .main-wrapper {
  opacity: 0;
}
.icon-load {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transform: translate3d(0, -100%, 0);
  transition: all 0.25s ease-out;
}
.loaded .icon-load {
  transform: translate3d(0, 0%, 0);
}
.sk-folding-cube {
  width: 40px;
  height: 40px;
  transform: rotateZ(45deg);
}
.sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  background: #fff;
  transform: scale(0.96);
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #008000;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  transform: scale(0.97) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  transform: scale(0.97) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  transform: scale(0.97) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  animation-delay: 0.9s;
}
input::placeholder,
textarea::placeholder {
  transition: opacity 0.2s ease-out;
}
input:focus::placeholder,
textarea:focus::placeholder {
  opacity: 0;
}
@-moz-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@-o-keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@font-face {
  font-family: "Exo2";
  src: url("../fonts/Exo2-Regular.eot");
  src: local("Exo2 Regular"), url("../fonts/Exo2-Regular.eot?#iefix") format('embedded-opentype'), url("../fonts/Exo2-Regular.woff2") format('woff2'), url("../fonts/Exo2-Regular.woff") format('woff'), url("../fonts/Exo2-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Exo2";
  src: url("../fonts/Exo2-Medium.eot");
  src: local("Exo2 Medium"), url("../fonts/Exo2-Medium.eot?#iefix") format('embedded-opentype'), url("../fonts/Exo2-Medium.woff2") format('woff2'), url("../fonts/Exo2-Medium.woff") format('woff'), url("../fonts/Exo2-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Exo2";
  src: url("../fonts/Exo2-Bold.eot");
  src: local("Exo2 Bold"), url("../fonts/Exo2-Bold.eot?#iefix") format('embedded-opentype'), url("../fonts/Exo2-Bold.woff2") format('woff2'), url("../fonts/Exo2-Bold.woff") format('woff'), url("../fonts/Exo2-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
.footer {
  width: 100%;
  min-width: 320px;
  background: #4e47ec;
  padding-top: 50px;
}
.footer__title {
  font-size: 25px;
  line-height: 25px;
  color: #fff;
}
.footer__wrapper {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  gap: 54px 21px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.footer__item {
  width: 245px;
}
.footer__item-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  margin-bottom: 10px;
  max-width: 245px;
}
.footer__item-desc {
  margin: 0;
  text-align: left;
  max-width: 245px;
  font-size: 14px;
  line-height: 24px;
  color: rgba(255,255,255,0.5);
}
.footer__list {
  margin-top: 20px;
  margin-bottom: 50px;
}
.footer__list-item {
  font-size: 14px;
  line-height: 24px;
  list-style-type: disc;
  color: #fff;
  margin-left: 15px;
}
.footer__list-item:not(:last-child) {
  margin-bottom: 15px;
}
.footer__desc {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
}
.footer__desc p {
  margin: 0;
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  color: rgba(255,255,255,0.5);
}
.footer__copyright {
  padding: 18px 0;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}
.footer .wrapper__copyright {
  background: #4942e8;
  max-width: 100%;
  margin-top: 24px;
}
.header {
  width: 100%;
  height: 80px;
  min-width: 320px;
  order: 1;
}
.main-wrapper {
  padding: 0 0 0 0;
  min-width: 320px;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.wrapper {
  min-width: 320px;
  max-width: 1090px;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
}
p {
  font-size: 14px;
  line-height: 18px;
  font-family: 'Exo2', sans-serif;
  color: #000;
  font-weight: 400;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  max-width: 516px;
  margin: 0 auto 50px;
}
h1 {
  font-size: 36px;
  line-height: 42px;
  font-family: 'Exo2', sans-serif;
  color: #008000;
  font-weight: 700;
  font-weight: 700;
  font-size: 50px;
  line-height: 65px;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
}
h2 {
  font-size: 32px;
  line-height: 37px;
  font-family: 'Exo2', sans-serif;
  color: #008000;
  font-weight: 700;
}
h3 {
  font-size: 28px;
  line-height: 32px;
  font-family: 'Exo2', sans-serif;
  color: #008000;
  font-weight: 700;
}
a {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  align-items: center;
}
.main {
  padding-top: 60px;
  padding-bottom: 120px;
  min-width: 320px;
  width: 100%;
  flex-grow: 1;
  background-image: url("../img/main/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.hero__wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
.hero__item {
  width: 336px;
  height: 327px;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  padding: 46px 30px 36px;
  justify-content: flex-start;
  border-radius: 30px;
  background-image: url("../img/main/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  filter: drop-shadow(14px 14px 20px rgba(0,0,0,0.08));
}
.hero__item-role {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  align-items: center;
  text-align: right;
  color: #fff;
  opacity: 1;
  margin: 0 0 2px 0;
}
.hero__item-license {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  align-items: center;
  text-align: right;
  color: #fff;
  opacity: 1;
  margin: 0 0 4px 0;
}
.hero__item-number {
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  align-items: center;
  text-align: right;
  color: #ffd11b;
  opacity: 1;
  margin: 0;
}
.hero__item-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hero__item-line:not(:last-child) {
  margin-bottom: 20px;
}
.hero__item-line-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  text-align: left;
  opacity: 0.8;
}
.hero__item-line-amount {
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  align-items: center;
  color: #000;
}
.hero__item-pic {
  width: 218px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 25px;
}
.hero__item-button {
  font-size: 22px;
  line-height: 22px;
  font-weight: 700;
  color: #fff;
  display: block;
  width: 278px;
  text-align: center;
  background-image: url("../img/main/bg-button.png");
  background-size: cover;
  padding: 21px 0 34px;
  margin: auto auto 0;
  position: relative;
}
.hero__item-button:hover {
  transform: scale(1.05) rotate(-1deg);
}
@media (max-width: 480px) {
  .main {
    padding: 33px 0 80px;
    background-image: url("../img/main/bg-phone.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    max-width: 310px;
    margin: 0 auto 25px;
  }
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #fff;
    margin-bottom: 5px;
  }
  a {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    align-items: center;
  }
  .hero__wrapper {
    gap: 25px;
  }
  .hero__item {
    width: 335px;
  }
  .hero__item-button {
    width: 278px;
  }
  .footer {
    padding-top: 40px;
  }
  .footer__wrapper {
    justify-content: center;
  }
  .footer__title {
    font-size: 16px;
    line-height: 22px;
    max-width: 100%;
  }
  .footer__item {
    width: 100%;
  }
  .footer__item-title {
    max-width: 100%;
  }
  .footer__item-desc {
    max-width: 100%;
  }
  .footer__wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 20px;
  }
  .footer__desc {
    gap: 10px;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .footer__desc p {
    max-width: 100%;
  }
  .footer__copyright {
    text-align: center;
    padding: 14px 0;
  }
  .footer .wrapper__copyright {
    margin-top: 0;
  }
}
@media (max-width: 320px) {
  .hero__item {
    padding: 46px 30px 36px;
    width: 336px;
  }
  .hero__item-button {
    width: 285px;
  }
}
