/*==========  Desktop First  ==========*/

// /* Large Devices, Wide Screens */
// @media (max-width : 1200px)
// /* Medium Devices, Desktops */
// @media (max-width : 992px)
// /* Small Devices, Tablets */
// @media (max-width : 768px)
// /* Small Devices, Tablets */
// @media (max-width : 568px)
// /* Small Devices, Tablets */
@media (max-width : 480px)
    .main
        padding: 33px 0 80px
        background-image: url('../img/main/bg-phone.jpg')
        background-repeat: no-repeat
        background-size: cover
    p
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #fff;
        max-width: 310px
        margin: 0 auto 25px

    h1
        font-weight: 700;
        font-size: 24px;
        line-height: 35px;
        color: #fff;
        margin-bottom 5px
    a
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        align-items: center;

    .hero
        &__wrapper
            gap: 25px
        &__item
            width: 335px
            &-button
                width: 278px

    .footer
        padding-top 40px
        &__wrapper
            justify-content: center
        &__title
            font-size 16px
            line-height 22px
            max-width: 100%
        &__item
            width: 100%
            &-title
                max-width: 100%
            &-desc
                max-width: 100%
        &__wrapper
            margin-top 30px
            margin-bottom 30px
            gap: 20px
        &__desc
            gap: 10px
            flex-direction: column
            margin-bottom 30px
            p
                max-width: 100%
        &__copyright
            text-align center
            padding: 14px 0
        .wrapper__copyright
            margin-top 0
        
// /* Custom, iPhone Retina */
@media (max-width : 320px)
    .hero
        &__item
            padding: 46px 30px 36px;
            width: 336px
            &-button
                width: 285px
/**/
